















































































































import { Component, Vue } from 'vue-property-decorator';
import MyNav from '../../components/Navbar.vue';
import axios from 'axios';
import slugify from 'slugify';
import Pusher from 'pusher-js';

@Component({
  components: {
    MyNav
  }
})
export default class Home extends Vue {
  public showDialog = false;
  public list: any = null
  public loading = false;
  public step = 0;
  public liveID = ''
  public errorMsg  = '';
  public chat = ''
  public videoID = ''
  public desc = '';
  public btn = '';
  public language = 'pt';
  public email = '';
  public question = '';
  public error = false;
  public edit: any = undefined;
  public boxMsg = '';
  public obj: any = this.reset(); 
  public user = '';
  public extras = []

  public reset() {
    return {    
      _id: null,
      name: '',
      slug: '',
      client: this.$route.params.code,
      bgLogin : '',
      bgLive : '',
      typeLive : null,
      hasPrize: false,
      hasQuestion: false,
      question: '',
      typeValidation : null,
      typeIdioma : null,
      linkExternal : null,
      addInputs : [],
      extraInputs : null,
      validationStrings : null,
      languages : {
        pt:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        },
        en:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        },
        es:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        }
      },
      addInput: null,
      chatLink: null
    }
  }

  public async sendEmoji(emoji:string){
    const obj = await axios.post(`${process.env.VUE_APP_API}emoji`,
      {
        channel:`channel-auren-aurenday2024`,
        emoji: emoji        
      })
  }

  public async entrar(){
    let count = 0
    if (this.email == '') return
    if (this.obj.languages.en.check) count++;
    if (this.obj.languages.pt.check) count++;
    if (this.obj.languages.es.check) count++;
    if (count<2) {
      await this.finish(); return;
    }
    this.step = 1;
  }

  public async submitQuestion(){
    this.loading = true;
    this.showDialog = false;
      if (this.question !== ''){
        const obj = await axios.post(`${process.env.VUE_APP_API}createComment`,
            {
                question : this.question,
                live : this.obj._id,
                user : localStorage.getItem(`user-aurenday2024`)
            })
        .then((d) => {return {error: false, body : d.data}; }).catch((e) => { return {error:true, body:null}})  
        if (obj.error){
            this.showDialog = true;
            this.errorMsg = 'Tente novamente';
        } else {
            this.showDialog = true;
            this.errorMsg = '';
            this.question = '';
        }        
      }
    this.loading = false;
  }

  public changeLanguage(lang:string){
    window.location.href = `aurenday2024?lang=${lang}`;
  }

  public async finish(){
    const obj = await axios.post(`${process.env.VUE_APP_API}createUser`,{
      live: this.obj._id,
      email: this.email,
      languages: this.language,
      addInputs: this.extras
    })
    .then((d) => {return {error: false, body : d.data}; }).catch((e) => { return {error:true, body:null}})        
    if (!obj.error){
      localStorage.setItem(`user-aurenday2024`,obj.body._id);
      window.location.href = `auren/aurenday2024`;
    }
  }

  public async atualiza(){
    this.list = null
    this.obj = this.reset();
    this.loading = true;
    const obj = await axios.get(`${process.env.VUE_APP_API}live-read?client=auren&slug=aurenday2024`)
    .then((d) => {return {error: false, body : d.data}; }).catch((e) => { return {error:true, body:null}})        
    if (!obj.error){
      this.obj = obj.body;
      document.title = `${obj.body.name} - ${obj.body.client.name}`;  
      if (this.obj.typeLive == 1){
          console.log
      }else{
        if (this.obj.typeIdioma == 1){
            this.videoID = this.obj.linkExternal;
            this.chat  = this.obj.chatLink;
            this.liveID = this.obj._id;
            this.btn = 'Enviar';
            this.desc = 'Envie sua pergunta para o apresentador';
        }else {
            //@ts-ignore
            if(this.$route.query.lang !== undefined && this.$route.query.lang !== null){ 
                //@ts-ignore
                this.videoID = (this.obj.languages[this.$route.query.lang].link)
                //@ts-ignore
                this.chat = (this.obj.languages[this.$route.query.lang].chat)
                //@ts-ignore
                this.btn = (this.obj.languages[this.$route.query.lang].btn)
                //@ts-ignore
                this.desc = (this.obj.languages[this.$route.query.lang].desc)
                this.liveID = this.obj._id;
            } else {
              //@ts-ignore
                this.videoID = (this.obj.languages['pt'].link)
                //@ts-ignore
                this.chat = (this.obj.languages['pt'].chat)
                //@ts-ignore
                this.btn = (this.obj.languages['pt'].btn)
                //@ts-ignore
                this.desc = (this.obj.languages['pt'].desc)
                this.liveID = this.obj._id;
            }
        }
      }
    }
    this.loading = false;
  }

  public pusher = new Pusher('c5e223f19015c40d4a2c', {
      cluster: 'us2'
  });
  


  public async created(){
    document.title = 'Carregando....';        
    await this.atualiza();
    
  }


}
